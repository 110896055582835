import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CsTingCardRoutingModule } from './cs-ting-card-routing.module';
import { CsTingCardListComponent } from './cs-ting-card-list/cs-ting-card-list.component';
import {MatIconModule, MatToolbarModule} from '@angular/material';
import {AllMaterialModule} from '../../all-material-module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
  declarations: [CsTingCardListComponent],
  imports: [
    CommonModule,
    CsTingCardRoutingModule,
    AllMaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FormsModule
  ]
})
export class CsTingCardModule { }
