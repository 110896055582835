import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-image-fullscreen-dialog',
  templateUrl: './image-fullscreen-dialog.component.html',
  styleUrls: ['./image-fullscreen-dialog.component.css']
})
export class ImageFullscreenDialogComponent implements OnInit {

  localData: any;
  imageUrl = '';

  constructor(
      public dialogRef: MatDialogRef<ImageFullscreenDialogComponent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: Object
  ) {

    this.localData = data;
    this.imageUrl =  this.localData.imageUrl;
  }

  ngOnInit() {
  }

  closeImage() {
    this.dialogRef.close();
  }
}
