import {ChangeDetectorRef, Component, HostBinding, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatTableDataSource, PageEvent} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import {of, Subject} from 'rxjs';
import {NotifierService} from 'angular-notifier';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MediaMatcher} from '@angular/cdk/layout';
import {MultiLanguageService} from '../../../shared/translate/multiLanguageService';
import {flatMap} from 'rxjs/operators';
import {IResponse, IResponseItems} from '../../../shared/interfaces/response';
import {HttpErrorResponse} from '@angular/common/http';
import {CsTingCardService} from '../cs-ting-card.service';

@Component({
  selector: 'app-cs-ting-card-list',
  templateUrl: './cs-ting-card-list.component.html',
  styleUrls: ['./cs-ting-card-list.component.css']
})
export class CsTingCardListComponent implements OnInit {

  @HostBinding('class.dv-list-page') layout = true;
  prefixName = 'tingcard_list';
  title = 'Ting card';

  loading = true;
  closeCardLoading = false;

  mobileQuery: MediaQueryList;
  mobileQueryListener: () => void;

  filterForm: FormGroup;
  statusStyles = {
    'CREATED': 'label-primary',
    'ACTIVATED': 'label-success',
    'BLOCKED': 'label-warning',
    'CLOSING': 'label-danger',
    'SUSPENDED': 'label-default'
  };

  sortOptions = [
    {
      id: 'id',
      text: 'ID at ASC'
    },
    {
      id: '-id',
      text: 'ID at DESC'
    }
  ];

  statusOptions = [
    {
      id: 'CREATED',
      text: 'CREATED'
    },
    {
      id: 'ACTIVATED',
      text: 'ACTIVATED'
    },
    {
      id: 'BLOCKED',
      text: 'BLOCKED'
    },
    {
      id: 'CLOSING',
      text: 'CLOSING'
    },
    {
      id: 'SUSPENDED',
      text: 'SUSPENDED'
    },
  ];

  selectedColumns = [
    'index',
    'card_holder_name',
    'masked_pan',
    'expired_at',
    'status',
    'actions',
  ];
  allColumns = [
    'index',
    'card_holder_name',
    'masked_pan',
    'expired_at',
    'status',
    'transaction_limit',
    'withdraw_limit',
    'allow_ecom',
    'allow_withdraw',
    'allow_cless',
    'actions',
  ];

  pageIndex = 0;
  pageSize = 20;
  pageSizeOptions = [5, 10, 20, 50];
  pageLength = 0;

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  selection = new SelectionModel<Selection>(true, []);

  refreshPageDataTrigger: Subject<any>;
  datePipe;

  constructor(
    private cardService: CsTingCardService,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    media: MediaMatcher,
    changeDetectorRef: ChangeDetectorRef,
    public multiLanguageService: MultiLanguageService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    if (this.mobileQuery.addEventListener) {
      this.mobileQuery.addEventListener('', this.mobileQueryListener);

    } else {
      this.mobileQuery.addListener(this.mobileQueryListener);

    }

    this.filterForm = this.formBuilder.group({
      search: null,
      ordering: null,
      status: null,
      phone_number: null
    }, );
  }

  ngOnInit() {
    this.multiLanguageService.onSetupMultiLanguage(this.prefixName);
    this.titleService.setTitle(this.title);

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.ordering === undefined) {
        this.filterForm.controls.ordering.setValue('');
      } else {
        this.filterForm.controls.ordering.setValue(params.ordering);
      }
      if (params.search === undefined) {
        this.filterForm.controls.search.setValue('');
      } else {
        this.filterForm.controls.search.setValue(params.search);
      }
      if (params.status === undefined) {
        this.filterForm.controls.status.setValue('');
      } else {
        this.filterForm.controls.status.setValue(params.status);
      }
      if (params.phone_number == undefined) {
        this.filterForm.controls.phone_number.setValue('');
      } else {
        this.filterForm.controls.phone_number.setValue(params.phone_number)
      }

      if (params.pageIndex === undefined || params.pageSize === undefined) {
        this.pageIndex = 0;
        this.pageSize = 20;
        this.registerEvents();
        this._triggerRefreshPageData();
        return;
      }
      this.pageIndex = params.pageIndex;
      this.pageSize = params.pageSize;
      this.registerEvents();
      this._triggerRefreshPageData();
    });
  }

  ngOnDestroy() {
    if (this.mobileQuery.removeEventListener) {
      this.mobileQuery.removeEventListener('', this.mobileQueryListener);
    } else {
      this.mobileQuery.removeListener(this.mobileQueryListener);
    }
  }

  private registerEvents() {
    this.registerRefreshDataEvent();

  }

  public onRefreshClicked() {
    this._triggerRefreshPageData();
  }

  private _triggerRefreshPageData() {
    this.refreshPageDataTrigger.next();
  }

  public onPageChange(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    const queryParams = this.buildParams();
    queryParams.pageIndex = this.pageIndex;
    queryParams.pageSize = this.pageSize;
    this.router.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
      });
  }

  public onFilterSubmit() {
    this.pageIndex = 0;
    const queryParams = this.buildParams();
    queryParams.pageIndex = this.pageIndex;
    queryParams.pageSize = this.pageSize;
    this.router.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
      });
  }

  public onFilterClear() {
    this.filterForm.reset();
  }

  get displayColumns() {
    return this.selectedColumns;
  }

  private registerRefreshDataEvent() {
    this.refreshPageDataTrigger = new Subject();
    this.refreshPageDataTrigger.pipe(
      flatMap(() => {
        this.loading = true;
        const params = this.buildParams();
        return this.cardService.list(params);
      }),
      flatMap((response: IResponse) => {
        if (!response.success) {
          let msg = 'Get data failed! Please contact your admin or retry later';
          try {
            msg = response.messages.error[0];
          } catch (error) {
          }
          this.notifier.notify('error', msg);
          this.loading = false;
          return;
        }
        this.parseResponse(response.data);
        return of(null);
      }),
    ).subscribe(
      () => {
        this.loading = false;
        console.log('success');
      },
      (error) => {
        console.log('error');
        console.log(error);
        if (error instanceof HttpErrorResponse && error.status === 403) {
          console.log(error);
          this.loading = false;
          return this.router.navigate(['/authentication/403'], {replaceUrl: true});
        }
        this.loading = false;
        this.registerRefreshDataEvent();
      },
      () => {
        console.log('complete');
        this.loading = false;
        this.registerRefreshDataEvent();
      }
    );
  }

  private buildParams() {
    const data = this.filterForm.getRawValue();
    data.offset = this.pageIndex * this.pageSize;
    data.limit = this.pageSize;
    return data;
  }

  private parseResponse(raw_data: IResponseItems) {
    this.pageLength = raw_data.pagination.total;
    this.dataSource.data = raw_data.items;
  }

  public trueFalseTransform(isEnabled) {
    if (isEnabled == null) {
      return ""
    } else if (isEnabled) {
      return "Enabled"
    } else {
      return "Disabled";
    }
  }

  lock(cardId: string) {
    if (this.closeCardLoading) {
      return;
    }

    this.closeCardLoading = true;

    this.cardService.lockCard(cardId).subscribe(
      (response: IResponse) => {
        if (response == null) {
          this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
          this.closeCardLoading = false;
          return;
        }

        if (!response.success) {
          let msg = 'Lock card failed! Please contact your admin or retry later';
          try {
            msg = response.errors[0];
          } catch (error) {
          }
          this.notifier.notify('error', msg);
          this.closeCardLoading = false;
          return;
        }

        let msg = 'Lock card success';
        try {
          msg = response.messages.success[0];
        } catch (error) {
        }
        this.notifier.notify('success', msg);
        this.closeCardLoading = false;
        this._triggerRefreshPageData();
      },
      (error) => {
        this._onApiError(error, "Lock card failed")
      },
      () => {}
    )
  }

  _onApiError(error, defaultErrorMessage) {
    if (error instanceof HttpErrorResponse && error.status === 500) {
      console.log(error);
      this.notifier.notify('error', 'Server Error! Please contact your admin or retry later');
      this.closeCardLoading = false;
      return;
    }
    if (error instanceof HttpErrorResponse && error.status === 403) {
      console.log(error);
      this.notifier.notify('error', 'You don\`t have permission to perform this action. Please contact your admin for further information');
      this.closeCardLoading = false;
      return;
    }

    const msg = error.message ? error.message : defaultErrorMessage;
    this.notifier.notify('error', msg);
    this.closeCardLoading = false;
  }

}
