import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CsTingCardService {

  constructor(
    private httpClient: HttpClient
  ) { }

  list(inputs) {
    const url = environment.api_base_url + '/customer-care/tingcard/';
    const params = this._buildParams(inputs);

    return this.httpClient.get(url, {params: params}).pipe(
      map(results => {
        return results;
      }),

      catchError(err => {
        throw err;
      })
    );
  }

  private _buildParams(inputs) {
    const encodedData = jQuery.param(inputs);
    return new HttpParams({fromString: encodedData});
  }

  lockCard(cardId) {
    const url = environment.api_base_url + '/customer-care/tingcard/' + cardId.toString() + '/lock';
    return this.httpClient.post(url, null).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }
}
