import { NgModule } from '@angular/core';

import { MenuItems } from './menu-items/menu-items';
import {
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective
} from './accordion';
import {AjaxValidatorService} from './validator/ajax-validator.service';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import {MatButtonModule} from '@angular/material';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import {DvImageComponent} from './utils/dv-image/dv-image.component';
import {ImageFullscreenDialogComponent} from './utils/image-fullscreen-dialog/image-fullscreen-dialog.component';

@NgModule({
    declarations: [
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective,
        AlertDialogComponent,
        ConfirmDialogComponent,
        DvImageComponent,
        ImageFullscreenDialogComponent,
    ],
    exports: [
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective,
        DvImageComponent,
    ],
    imports: [
        MatButtonModule
    ],
    providers: [
        MenuItems,
        AjaxValidatorService,
    ],
    entryComponents: [
        AlertDialogComponent,
        ConfirmDialogComponent,
        ImageFullscreenDialogComponent,
    ]
})
export class SharedModule {}
