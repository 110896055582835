<mat-toolbar class="dv-header">
  <h3>{{ prefixName + '.title' | translate }}</h3>
  <span fxFlex="1 1 0%"></span>
  <button mat-button (click)="onRefreshClicked()">
    <mat-icon>refresh</mat-icon>
    REFRESH
  </button>
</mat-toolbar>

<div class="dv-content">
  <mat-drawer-container class="dv-drawer-container">
    <mat-drawer #filterDrawer [mode]="'over'" position="end" class="dv-drawer-menu" fxLayout="column">
      <mat-card class="dv-drawer-menu-content">
        <mat-card-content>
          <form [formGroup]="filterForm" #filterFormWrapper>
            <mat-label>Sort By</mat-label>
            <mat-form-field appearance="outline" floatLabel="never">
              <mat-select [formControl]="filterForm.controls.ordering">
                <mat-option [value]="null">--</mat-option>
                <mat-option *ngFor="let sortOption of sortOptions" [value]="sortOption.id">
                  {{sortOption.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <hr>
            <mat-label>Search</mat-label>
            <mat-form-field appearance="outline" floatLabel="never">
              <input matInput [formControl]="filterForm.controls.search">
              <mat-hint>Search by Card ID, Card Holder Name, Status</mat-hint>
            </mat-form-field>
            <mat-label>{{ prefixName + '.search.status' | translate }}</mat-label>
            <mat-form-field appearance="outline" floatLabel="never">
              <mat-select [formControl]="filterForm.controls.status">
                <mat-option [value]=null>--</mat-option>
                <mat-option *ngFor="let statusOption of statusOptions" [value]="statusOption.id">
                  {{statusOption.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-label>{{ prefixName + '.search.phone_number' | translate }}</mat-label>
            <mat-form-field appearance="outline" floatLabel="never">
              <input matInput [formControl]="filterForm.controls.phone_number">
            </mat-form-field>
          </form>
        </mat-card-content>
      </mat-card>
      <mat-toolbar class="dv-drawer-menu-footer">
        <div class="button-row">
          <button mat-raised-button color="primary" (click)="filterDrawer.toggle(); onFilterSubmit()">Apply
          </button>
          <button mat-raised-button (click)="onFilterClear()">Clear</button>
        </div>
      </mat-toolbar>
    </mat-drawer>
    <mat-drawer-content class="dv-drawer-content">
      <div *ngIf="loading" class="dv-layout-mat-spinner-container">
        <mat-spinner [diameter]="30" [strokeWidth]="3" class="dv-layout-mat-spinner"></mat-spinner>
      </div>
      <mat-toolbar class="dv-drawer-content-header">
        <span fxFlex="1 1 0%"></span>
        <div class="dv-column-selector">
          <mat-select [(ngModel)]="selectedColumns" [multiple]="true" style="width: 100%"
                      placeholder="Columns">
            <mat-select-trigger>Columns</mat-select-trigger>
            <mat-option *ngFor="let column of allColumns" [value]="column">{{column}}</mat-option>
          </mat-select>
        </div>
        <button mat-icon-button (click)="filterDrawer.toggle()">
          <mat-icon>filter_list</mat-icon>
        </button>
      </mat-toolbar>
      <mat-toolbar class="dv-drawer-content-header filter-row">
        <form [formGroup]="filterForm" #filterFormWrapper>
          <mat-toolbar-row>
            <div class="m-l-9 m-t-9">
              <mat-form-field appearance="outline" floatLabel="never">
                <mat-label>{{ prefixName + '.search.phone_number' | translate }}</mat-label>
                <input matInput [formControl]="filterForm.controls.phone_number">
              </mat-form-field>
            </div>
            <div class="m-l-9 m-t-9">
              <mat-form-field appearance="outline" floatLabel="never">
                <mat-label>{{ prefixName + '.search.status' | translate }}</mat-label>
                <mat-select [formControl]="filterForm.controls.status">
                  <mat-option [value]=null>--</mat-option>
                  <mat-option *ngFor="let statusOption of statusOptions" [value]="statusOption.id">
                    {{statusOption.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="m-l-9">
              <button mat-raised-button color="primary" (click)="onFilterSubmit()">Apply</button>
            </div>
            <div class="m-l-9">
              <button mat-raised-button (click)="onFilterClear()">Clear</button>
            </div>
          </mat-toolbar-row>
        </form>
      </mat-toolbar>
      <div class="dv-drawer-content-data">
        <div class="responsive-table">
          <mat-table [dataSource]="dataSource">
            <!-- Column definition-->
            <ng-container matColumnDef="index">
              <mat-header-cell *matHeaderCellDef>{{ prefixName + '.column.no' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let row; let i = index;">
                <span class="header-label">{{ prefixName + '.column.no' | translate }}: </span>{{pageIndex * pageSize + i + 1}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="card_holder_name">
              <mat-header-cell *matHeaderCellDef>{{ prefixName + '.column.card_holder_name' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="header-label">{{ prefixName + '.column.card_holder_name' | translate }}</span>{{ row.item?.card_holder_name  }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="masked_pan">
              <mat-header-cell *matHeaderCellDef>{{ prefixName + '.column.pan' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="header-label">{{ prefixName + '.column.pan' | translate }}: </span>{{ row.item?.masked_pan  }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="expired_at">
              <mat-header-cell *matHeaderCellDef>{{ prefixName + '.column.expiry_at' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="header-label">{{ prefixName + '.column.expiry_at' | translate }}: </span>{{ row.item?.expired_at | date: " MM/y"  }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef>{{ prefixName + '.column.status' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="header-label">{{ prefixName + '.column.status' | translate }}: </span>
                <span class="label {{statusStyles[row?.item?.status]}}">{{ row.item?.status  }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="transaction_limit">
              <mat-header-cell *matHeaderCellDef>{{ prefixName + '.column.transaction_limit' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="header-label">{{ prefixName + '.column.transaction_limit' | translate }} : </span>{{ row.item?.card_program?.transaction_limit | number  }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="withdraw_limit">
              <mat-header-cell *matHeaderCellDef>{{ prefixName + '.column.withdraw_limit' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="header-label">{{ prefixName + '.column.withdraw_limit' | translate }} : </span>{{ row.item?.card_program?.withdraw_limit | number  }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="allow_ecom">
              <mat-header-cell *matHeaderCellDef>{{ prefixName + '.column.ecommerce' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="header-label">{{ prefixName + '.column.ecommerce' | translate }}: </span>{{ trueFalseTransform(row.item?.card_program?.allow_ecommerce) }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="allow_withdraw">
              <mat-header-cell *matHeaderCellDef>{{ prefixName + '.column.withdraw' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="header-label">{{ prefixName + '.column.withdraw' | translate }} : </span>{{ trueFalseTransform(row.item?.card_program?.allow_withdrawn) }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="allow_cless">
              <mat-header-cell *matHeaderCellDef>{{ prefixName + '.column.contactless' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="header-label">{{ prefixName + '.column.contactless' | translate }} : </span>{{ trueFalseTransform(row.item?.card_program?.allow_contactless) }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let row">
                <button mat-icon-button [matMenuTriggerFor]="menuActions" *ngIf="(row.item?.can_lock && row.actions?.lock?.user_has_perm)">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menuActions="matMenu">
                  <button (click)="lock(row.item?.id)" mat-menu-item *ngIf="row.item?.can_lock && row.actions?.lock?.user_has_perm">{{ prefixName + '.action.lock' | translate }}</button>
                </mat-menu>
              </mat-cell>
            </ng-container>

            <!-- Row definition -->
            <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; let i = index; columns: displayColumns;"></mat-row>
          </mat-table>
        </div>
      </div>
      <mat-toolbar class="dv-drawer-content-footer">
        <mat-paginator
                [length]="pageLength"
                [pageIndex]="pageIndex"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                (page)="onPageChange($event)"
                showFirstLastButtons
        ></mat-paginator>
      </mat-toolbar>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
