import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactHistoryComponent} from './contact-history/contact-history.component';
import {RouterModule} from '@angular/router';
import {CustomerCareRoutes} from './customer-care.routing';
import {IncomingModules} from './incoming/incoming.modules';
import {CsUserModule} from './cs-user/cs-user.module';
import {CallHistoryModule} from './call-history/call-history.module';
import { CsTingCardModule } from './cs-ting-card/cs-ting-card.module';
@NgModule({
    declarations: [
        ContactHistoryComponent,
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(CustomerCareRoutes),
        IncomingModules,
        CsUserModule,
        CallHistoryModule,
        CsTingCardModule,
    ]
})
export class CustomerCareModule {
}
