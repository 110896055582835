import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {flatMap} from 'rxjs/operators';
import {IResponse} from '../interfaces/response';
import {of} from 'rxjs';
import {Router} from '@angular/router';

export interface BadgeItem {
  type: string;
  value: string;
  roles?: string[];
}

export interface Separator {
  name: string;
  type?: string;
  roles?: string[];
}

export interface SubChildren {
  state: string;
  name: string;
  type?: string;
  roles?: string[];
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
  roles?: string[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  separator?: Separator[];
  children?: ChildrenItems[];
  roles?: string[];
}

const MENUITEMS = [
  {
    state: '',
    name: 'ADMIN',
    type: 'separator',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'IS_SUPERUSER']
  },
  {
    state: 'sysadmin/iam',
    name: 'IAM',
    type: 'sub',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'IS_SUPERUSER'],
    children: [
      {state: 'backend-user', name: 'Backend users', type: 'link', roles: ['ADMIN', 'IS_SUPERUSER']},
      {state: 'op-member', name: 'Operator members', type: 'link', roles: ['ADMIN', 'IS_SUPERUSER']},
      {state: 'government-member', name: 'Government members', type: 'link', roles: ['ADMIN', 'IS_SUPERUSER']},
    ]
  },
  {
    state: 'sysadmin/business',
    name: 'Business',
    type: 'sub',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'IS_SUPERUSER'],
    children: [
      {state: 'manage', name: 'Manage', type: 'link', roles: ['ADMIN', 'IS_SUPERUSER']},
    ]
  },
  {
    state: 'sysadmin/payment-category',
    name: 'Payment Category',
    type: 'link',
    roles: ['ADMIN'],
    icon: 'fiber_manual_record',
  },
  {
    state: 'wallet',
    name: 'Wallets',
    type: 'link',
    roles: ['ADMIN'],
    icon: 'fiber_manual_record',
  },
  {
    state: 'sysadmin/payment-method-settings',
    name: 'Payment Method Settings',
    type: 'link',
    roles: ['ADMIN'],
    icon: 'fiber_manual_record',
  },
  {
    state: 'sysadmin/account-history',
    name: 'Transaction log',
    type: 'sub',
    icon: 'fiber_manual_record',
    roles: ['ADMIN'],
    children: [
      {state: 'processing', name: 'Processing transaction', type: 'link', roles: ['ADMIN']},
      {state: 'all-transaction', name: 'All transaction', type: 'link', roles: ['ADMIN']},
      {state: 'business-withdraw', name: 'Business withdraw', type: 'link', roles: ['ADMIN']},
    ]
  },
  {
    state: 'sysadmin/location',
    name: 'Location',
    type: 'sub',
    roles: ['ADMIN'],
    icon: 'fiber_manual_record',
    children: [
      {state: 'list', name: 'List', type: 'link', roles: ['ADMIN']},
      {state: 'order', name: 'Order', type: 'link', roles: ['ADMIN']},
    ]
  },
  {
    state: 'anti-money-laundering',
    name: 'Anti Money Laundering',
    type: 'sub',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'IS_SUPERUSER'],
    children: [
      {state: 'config-rules', name: 'Config Rules', type: 'link', roles: ['ADMIN', 'IS_SUPERUSER']},
      {state: 'report-fd001', name: 'Report FD001', type: 'link', roles: ['ADMIN', 'IS_SUPERUSER']},
      {state: 'report-fd002', name: 'Report FD002', type: 'link', roles: ['ADMIN', 'IS_SUPERUSER']},
      {state: 'report-fd003', name: 'Report FD003', type: 'link', roles: ['ADMIN', 'IS_SUPERUSER']},
      {state: 'report-fd004', name: 'Report FD004', type: 'link', roles: ['ADMIN', 'IS_SUPERUSER']},
      {state: 'report-fd005', name: 'Report FD005', type: 'link', roles: ['ADMIN', 'IS_SUPERUSER']},
    ]
  },
  {
    state: 'sysadmin/setting',
    name: 'Setting',
    type: 'link',
    roles: ['ADMIN'],
    icon: 'fiber_manual_record',
  },
  {
    state: 'sysadmin/registration-whitelist',
    name: 'Registration whitelist',
    type: 'link',
    roles: ['ADMIN'],
    icon: 'fiber_manual_record',
  },
  {
    state: 'need-to-update-data',
    name: 'Need to update data',
    type: 'link',
    roles: ['ADMIN'],
    icon: 'fiber_manual_record',
  },
  {
    state: 'sysadmin/config-frontend-version',
    name: 'Config frontend version',
    type: 'link',
    roles: ['ADMIN'],
    icon: 'fiber_manual_record',
  },
  {
    state: 'sysadmin/configuration-settings',
    name: 'Configuration settings',
    type: 'link',
    roles: ['ADMIN'],
    icon: 'fiber_manual_record',
  },
    {
        state: 'sysadmin/pay-qr-settings',
        name: 'PayQR settings',
        type: 'sub',
        roles: ['ADMIN'],
        icon: 'fiber_manual_record',
        children: [
            {state: 'list', name: 'Settings', type: 'link', roles: ['ADMIN']},
            {state: 'merchant-list', name: 'Merchants', type: 'link', roles: ['ADMIN']}
        ]
    },
  {
    state: '',
    name: 'OPERATION',
    type: 'separator',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']
  },
  {
    state: 'operation/dashboards',
    name: 'Dashboard',
    type: 'link',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']
  },
  {
    state: 'operation/kyc',
    name: 'KYC request',
    type: 'sub',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF'],
    children: [
      {state: 'list', name: 'List', type: 'link', roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']},
      {state: 'tags', name: 'Error tags', type: 'link', roles: ['ADMIN', 'OPERATION_ADMIN']},
      {state: 'ethnic', name: 'Ethnic', type: 'link', roles: ['ADMIN', 'OPERATION_ADMIN']},
      {state: 'religion', name: 'Religion', type: 'link', roles: ['ADMIN', 'OPERATION_ADMIN']},
      {state: 'place-of-issue', name: 'Place of issue', type: 'link', roles: ['ADMIN', 'OPERATION_ADMIN']},
    ]
  },
  {
      state: 'operation/kyc-faces-request',
      name: 'KYC faces request',
      type: 'sub',
      icon: 'fiber_manual_record',
      roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF'],
      children: [
          {state: 'list', name: 'List', type: 'link', roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']},
          {state: 'tags', name: 'Error tags', type: 'link', roles: ['ADMIN', 'OPERATION_ADMIN']},
      ]
    },
  {
    state: 'operation/additional-document',
    name: 'Additional document request',
    type: 'link',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF'],
  },
  {
    state: 'operation/document',
    name: 'Document list',
    type: 'link',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF'],
  },
  {
    state: 'operation/profile',
    name: 'Profile',
    type: 'link',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF'],
  },
  {
    state: 'operation/campaign',
    name: 'Campaigns',
    type: 'sub',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF'],
    children: [
      {state: 'beta-launch', name: 'Beta Launch', type: 'link', roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']},
      {state: 'email', name: 'Email campaign', type: 'link', roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']},
    ]
  },
  {
    state: 'operation/napas-whitelist',
    name: 'Napas whitelist',
    type: 'sub',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF'],
    children: [
      {
        state: 'control-panel',
        name: 'Control panel',
        type: 'link',
        roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']
      },
    ]
  },
  {
    state: 'operation/tingcard',
    name: 'Ting Card',
    type: 'sub',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF'],
    children: [
      {
        state: 'control-panel',
        name: 'Control panel',
        type: 'link',
        roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']
      },
      {
        state: 'whitelist-account',
        name: 'Whitelist',
        type: 'link',
        roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']
      },
      {
        state: 'ting-card',
        name: 'Ting cards',
        type: 'link',
        roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']
      },
      {
        state: 'transactions',
        name: 'Card transactions',
        type: 'link',
        roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']
      },
      {
        state: 'location/list',
        name: 'Location',
        type: 'link',
        roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']
      },
      {
        state: 'location/order',
        name: 'Location Order',
        type: 'link',
        roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']
      },
    ]
  },
  {
    state: 'operation/ting-web-manage',
    name: 'Ting Web Manage',
    type: 'sub',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF'],
    children: [
      {state: 'user-promos', name: 'User promos', type: 'link', roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']},
      {state: 'faq-manage', name: 'FAQ manage', type: 'link', roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']},
      {state: 'careers', name: 'Careers', type: 'link', roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']},
      {state: 'blog-manage', name: 'Blog manage', type: 'link', roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']},
      {state: 'landing-page-manage', name: 'Landing page manage', type: 'link', roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']},
      {state: 'home-page-manage', name: 'Home page manage', type: 'link', roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']},
    ]
  },
  {
    state: 'operation/auto-top-up-napas-bank-balance',
    name: 'Auto top up NAPAS bank balance',
    type: 'sub',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF'],
    children: [
      {
        state: 'control-panel',
        name: 'Control panel',
        type: 'link',
        roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']
      },
    ]
  },
  {
    state: 'operation/bidv',
    name: 'BIDV',
    type: 'sub',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF'],
    children: [
      {
        state: 'control-panel',
        name: 'Control panel',
        type: 'link',
        roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']
      },
      {
        state: 'whitelist',
        name: 'Whitelist',
        type: 'link',
        roles: ['ADMIN', 'OPERATION_ADMIN', 'OPERATION_STAFF']
      },
    ]
  },
  {
    state: '',
    name: 'ACCOUNTANT',
    type: 'separator',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF']
  },
  {
    state: 'accountant/reconcile',
    name: 'Reconcile',
    type: 'sub',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF'],
    children: [
      {state: 'vietbank', name: 'Vietbank', type: 'link', roles: ['ADMIN', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF']},
      {state: 'napas', name: 'Napas', type: 'link', roles: ['ADMIN', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF']},
      {state: 'tingcard', name: 'Ting Card', type: 'link', roles: ['ADMIN', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF']},
      {state: 'bidv', name: 'BIDV', type: 'link', roles: ['ADMIN', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF']},
      {
        state: 'increase-balance',
        name: 'Increase balance',
        type: 'link',
        roles: ['ADMIN', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF']
      },
      {
        state: 'decrease-balance',
        name: 'Decrease balance',
        type: 'link',
        roles: ['ADMIN', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF']
      },
        {state: 'vnpay', name: 'VNPAY', type: 'link', roles: ['ADMIN', 'ACCOUNTANT']},
    ]
  },
  {
    state: '',
    name: 'BANK ACCOUNTS',
    type: 'separator',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF']
  },
  {
    state: 'bank-accounts',
    name: 'Bank accounts',
    type: 'sub',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF'],
    children: [
      {
        state: 'system-bank-accounts',
        name: 'System bank accounts',
        type: 'link',
        roles: ['ADMIN', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF']
      },
      {
        state: 'guarantee-accounts',
        name: 'Guarantee accounts',
        type: 'link',
        roles: ['ADMIN', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF']
      },
    ]
  },
  {
    state: '',
    name: 'CUSTOMER SERVICES',
    type: 'separator',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'CUSTOMER_CARE_ADMIN', 'CUSTOMER_CARE_STAFF', 'CUSTOMER_CARE_OUT_SOURCE']
  },
  {
    state: 'customer-care/incoming',
    name: 'Incoming',
    type: 'link',
    roles: ['ADMIN', 'CUSTOMER_CARE_ADMIN', 'CUSTOMER_CARE_STAFF', 'CUSTOMER_CARE_OUT_SOURCE'],
    icon: 'fiber_manual_record',
  },
  {
    state: 'customer-care/cs-user',
    name: 'CS User',
    type: 'link',
    roles: ['ADMIN', 'CUSTOMER_CARE_ADMIN'],
    icon: 'fiber_manual_record',
  },
  {
    state: 'customer-care/call-history',
    name: 'Call History',
    type: 'link',
    roles: ['ADMIN', 'CUSTOMER_CARE_ADMIN'],
    icon: 'fiber_manual_record',
  },
  {
    state: 'customer-care/kyc-request',
    name: 'KYC request',
    type: 'link',
    roles: ['ADMIN', 'CUSTOMER_CARE_ADMIN', 'CUSTOMER_CARE_STAFF', 'CUSTOMER_CARE_OUT_SOURCE'],
    icon: 'fiber_manual_record',
  },
  {
    state: 'customer-care/kyc-faces-request',
    name: 'KYC faces request',
    type: 'link',
    roles: ['ADMIN', 'CUSTOMER_CARE_ADMIN', 'CUSTOMER_CARE_STAFF', 'CUSTOMER_CARE_OUT_SOURCE'],
    icon: 'fiber_manual_record',
  },
  {
    state: 'customer-care/additional-document-request',
    name: 'Additional document request',
    type: 'link',
    roles: ['ADMIN', 'CUSTOMER_CARE_ADMIN', 'CUSTOMER_CARE_STAFF', 'CUSTOMER_CARE_OUT_SOURCE'],
    icon: 'fiber_manual_record',
  },
  {
    state: 'customer-care/document-list',
    name: 'Document list',
    type: 'link',
    roles: ['ADMIN', 'CUSTOMER_CARE_ADMIN', 'CUSTOMER_CARE_STAFF', 'CUSTOMER_CARE_OUT_SOURCE'],
    icon: 'fiber_manual_record',
  },
  {
    state: 'customer-care/cs-ting-card/list',
    name: 'Ting Card',
    type: 'link',
    roles: ['ADMIN', 'CUSTOMER_CARE_ADMIN', 'CUSTOMER_CARE_STAFF', 'CUSTOMER_CARE_OUT_SOURCE'],
    icon: 'fiber_manual_record',
  },
  {
    state: '',
    name: 'TICKET SYSTEM',
    type: 'separator',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'CUSTOMER_CARE_ADMIN', 'CUSTOMER_CARE_STAFF', 'CUSTOMER_CARE_OUT_SOURCE', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF'],
  },
  {
    state: '/ticket/list',
    name: 'Ticket',
    type: 'link',
    roles: ['ADMIN', 'CUSTOMER_CARE_ADMIN', 'CUSTOMER_CARE_STAFF', 'CUSTOMER_CARE_OUT_SOURCE', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF'],
    icon: 'fiber_manual_record',
  },
  {
    state: 'ticket/request',
    name: 'Customer request',
    type: 'link',
    roles: ['ADMIN', 'CUSTOMER_CARE_ADMIN', 'CUSTOMER_CARE_STAFF', 'CUSTOMER_CARE_OUT_SOURCE', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF'],
    icon: 'fiber_manual_record',
  },
  {
    state: '/ticket/category',
    name: 'Category',
    type: 'link',
    roles: ['ADMIN', 'CUSTOMER_CARE_ADMIN', 'CUSTOMER_CARE_STAFF', 'CUSTOMER_CARE_OUT_SOURCE', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF'],
    icon: 'fiber_manual_record',
  },
  {
    state: '',
    name: 'GOVERNMENT BANK REPORT',
    type: 'separator',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF']
  },
  // {
  //     state: 'government-bank-report/quarter-report',
  //     name: 'Quarter Report',
  //     type: 'link',
  //     roles: ['ADMIN', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF'],
  //     icon: 'fiber_manual_record',
  // },
  {
    state: 'government-bank-report',
    name: 'Report',
    type: 'sub',
    icon: 'fiber_manual_record',
    roles: ['ADMIN', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF'],
    children: [
      {
        state: 'quarter-report',
        name: 'Quarter report',
        type: 'link',
        roles: ['ADMIN', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF']
      },
      {
        state: 'annual-report',
        name: 'Annual report',
        type: 'link',
        roles: ['ADMIN', 'ACCOUNTANT_ADMIN', 'ACCOUNTANT_STAFF']
      },
    ]
  },
  {
    state: '',
    name: 'TOOLS',
    type: 'separator',
    icon: 'fiber_manual_record',
    roles: ['*']
  },
  {
    state: 'tool/file',
    name: 'Files',
    type: 'link',
    roles: ['*'],
    icon: 'fiber_manual_record',
  },


];

@Injectable()
export class MenuItems {

  menuItems = [];

  private static _generateUrl() {
    return environment.api_base_url + '/me/roles';
  }

  private static _generateParams() {
    return new HttpParams();
  }

  private static _hasRole(userRoles, menuRoles) {
    if (userRoles && userRoles.indexOf('*') >= 0) {
      return true;
    }
    if (menuRoles && menuRoles.indexOf('*') >= 0) {
      return true;
    }
    if (!menuRoles || !userRoles) {
      return false;
    }

    return userRoles.filter(item => menuRoles.indexOf(item) >= 0).length > 0;
  }


  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {
  }

  public refreshMenuItem() {
    const requestUrl = MenuItems._generateUrl();
    const requestParams: HttpParams = MenuItems._generateParams();
    this.menuItems = [];

    return this.httpClient.get<any>(requestUrl,
      {params: requestParams}
    ).pipe(
      flatMap((res: IResponse) => {
        const roles: string[] = res.data.roles;
        if (res.data.is_superuser) {
          roles.push('IS_SUPERUSER');
        }
        const self = this;
        // @ts-ignore
        MENUITEMS.forEach(function (menu: Menu, index, array) {
          const _menu = menu;
          if (menu.type === 'sub') {
            const _children = [];
            const children = menu.children;
            children.forEach(function (child: ChildrenItems, _index, _array) {
              if (MenuItems._hasRole(roles, child.roles)) {
                _children.push(child);
              }
            });
            _menu.children = _children;
          }
          if (MenuItems._hasRole(roles, menu.roles)) {
            self.menuItems.push(_menu);
          }
        });
        return of(null);
      })
    );
  }

  getMenuItem(): Menu[] {
    return this.menuItems;
  }
}
