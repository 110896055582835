import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})

export class NeedToUpdateDataService {

    constructor(private httpClient: HttpClient, ) {
    }

    getDataConfig() {
        const url = environment.api_base_url + '/configuration-settings/';

        return this.httpClient.get(url).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                throw err;
            })
        );
    }

    migrateUpdateData(config_key) {
        let url = '';
        switch (config_key) {
            case 'MIGRATION.B2W_TRANSACTION':
                url = environment.api_base_url + '/migrate-data/transaction/b2w-update-data';
                break;
            case 'MIGRATION.ADDITIONAL_DATA_VB':
                url = environment.api_base_url + '/migrate-data/additional-data/update';
                break;
            case 'MIGRATION.REFRESH_REPORT_TABLE':
                url = environment.api_base_url + '/migrate-data/refresh-report-table/refresh-monthly-report';
                break;
            case 'MIGRATION.VB_SYSTEM_TRACE':
                url = environment.api_base_url + '/migrate-data/system-trace/';
                break;
            case 'MIGRATION.UPDATE_STATUS_WALLET_AMOUNT_BLOCK':
                url = environment.api_base_url + '/migrate-data/update-status-wallet-amount-block/update';
                break;
            case 'MIGRATION.UPDATE_TEXT_ID_GUARANTEED_ACCOUNT_TABLE':
                url = environment.api_base_url + '/migrate-data/update-guaranteed-account-table';
                break;
            case 'MIGRATION.REFRESH_QUARTER_REPORT_TABLE':
                url = environment.api_base_url + '/migrate-data/refresh-report-table/refresh-quarter-report';
                break;
            case 'MIGRATION.REFRESH_ANNUAL_REPORT_TABLE':
                url = environment.api_base_url + '/migrate-data/refresh-report-table/refresh-annual-report';
                break;
            case 'MIGRATION.B2W_BANK_HINT':
                url = environment.api_base_url + '/migrate-data/transaction/b2w-update-bank-hint';
                break;
            case 'MIGRATION.INSERT_BANK_SETTINGS':
                url = environment.api_base_url + '/migrate-data/bank-settings/insert-data';
                break;
            case 'MIGRATION.INSERT_MAPPING_VIETBANK_LOCATION':
                url = environment.api_base_url + '/migrate-data/mapping-vietbank-location/insert-data';
                break;
            default:
                break;
        }
        return this.httpClient.post(url, null).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                throw err;
            })
        );
    }
}
